.titleNF{
    text-align: center;
    padding-top: 1%;
    font-weight: normal;
    font-size: calc(2em+3vw);
    font-family: Raleway,sans-serif;
    color:white !important;
    padding-bottom: 2em;
}
.notFoundContent a{
    color: white;
    text-decoration: underline;
}
.notFoundContent{
    color:white;
    padding-top: 0%;
    font-family: Raleway,sans-serif;
    margin-top: 1.5em;
    font-weight: normal;
    max-width: 75%;
    font-size: 2em;
}
#containerNF{
        background-image: linear-gradient(135deg,rgb(255, 198, 12),rgb(255, 0, 0));
        width:80%;
        margin-left:10%;
        margin-top: 10%;
        margin-bottom: 2%;
        padding-bottom: 2em;
        border-radius: 2em;
        text-align: center;
        box-shadow:0px 15px 30px rgba(0,0,0,0.5)
       
}
@media(max-width:991px){
    
    #containerNF{
        
        margin-top: 16%;

    }
}
