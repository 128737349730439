.title{
    text-align: center;
    margin-top: 10%;
    font-weight: bold;
    font-size: calc(2em+3vw);
    font-family: Poppins,sans-serif;
    color:black;
    padding-bottom: 2em;
}
a:hover{
    text-decoration: none;
}
#thumbnail{
    border-radius: 3vw;
}
.projectBlock{
    display: inline;
}
ul{
    list-style: none;
    display: flex;
    justify-content: center;
}
li{
    padding-right:40px;
}

@media(min-width:992px){
    #thumbnail{
        width:25vw;
        max-width: 35em;
        height:auto;
    }
    ul{
       flex-direction: row; 

    }.title{
        
        font-size: 3vw;
    }
    
}
@media(max-width:991px){
    #thumbnail{
        width:35vw;
        height:auto;
    }
    ul{
        display: inline;
        text-align: center;
        margin-left: 22%;
    }
    li{
        padding-right: 0px;
    }
    .title{
        
        margin-top: 13%;

    }
}