@import url(https://fonts.googleapis.com/css?family=Raleway:regular,bold,italic,lighter&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext);
.title{
    text-align: center;
    margin-top: 10%;
    font-weight: bold;
    font-size: calc(2em+3vw);
    font-family: Poppins,sans-serif;
    color:black;
    padding-bottom: 2em;
}
a:hover{
    text-decoration: none;
}
#thumbnail{
    border-radius: 3vw;
}
.projectBlock{
    display: inline;
}
ul{
    list-style: none;
    display: flex;
    justify-content: center;
}
li{
    padding-right:40px;
}

@media(min-width:992px){
    #thumbnail{
        width:25vw;
        max-width: 35em;
        height:auto;
    }
    ul{
       flex-direction: row; 

    }.title{
        
        font-size: 3vw;
    }
    
}
@media(max-width:991px){
    #thumbnail{
        width:35vw;
        height:auto;
    }
    ul{
        display: inline;
        text-align: center;
        margin-left: 22%;
    }
    li{
        padding-right: 0px;
    }
    .title{
        
        margin-top: 13%;

    }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width:992px){
    form input{
        width: 60%;
    }
    .title{
        
        font-size: 3vw;
    }
}
@media (max-width:991px){
    form input{
        width:60%
    }
    .title{
        margin-top: 13%;
    }.iconContainer{
    
        
        margin: 0px;
        padding:0px;
        display: flex;
        flex-direction: row;
    }
}
form{
    text-align: center;
    margin-left: 13%;
}
#m{
    padding-bottom: 5em;
}
form input{
    margin-left: 13%;
    outline: none;
    border: none;
    display: block;
    border-bottom: 2px solid black;
    margin-top: 2em;
}
form input:hover{
    border-bottom: 2px solid #0CACFF;
}
.iconsContact{
    font-size: 3em;
    margin-top: 50%;
}
.mailIcon{
    font-size: 3.75em;
    margin-top: 30%;
}
.buttonWrap{
    z-index: -1;
    width: 300%;
    height: 100%;
    top: 0;
    left:0;
}
.submit{
    margin-top: 2em;
    margin-left: -15%;
    padding: 0 30px;
    border: none;
    text-decoration: none;
    border-radius: 20px !important;
    width:auto;
    height:60px;
    font-size: 1.25em;
    font-family: Poppins,Raleway,sans-serif;
    align-items: center;
    line-height: 1.2;
    color: white;
    background:linear-gradient( #0CACFF,#006FFF) ;
}

.submit:hover{
    background:#006FFF;
    box-shadow:0px 5px 10px rgba(0,0,0,0.5);
}
.submit:active{
    color: black;
    background:#FFF;
    box-shadow:0px 5px 10px rgba(0,0,0,0.5);
}
.title{
    margin-left: 0;
    text-align: center;
    font-weight: bold;
    font-size: calc(2em+3vw);
    
    font-family: Poppins,sans-serif;
    color:black
}
strong{
    color:red;
    margin-top: 2em;
    font-size: 2em;
    display: block;
    text-align: center;
}

footer{
    width: 100%;
    background-color: black;
    color:white;
    border-top: solid;
    text-align: center;
    font-size: 2em;
    border-top-width:thin;
    margin-top: 2%;
}
.footerName{
    padding-top:2%;
    font-family: Poppins,Raleway,sans-serif;
    font-weight: normal;
}
footer a{
    color: #0CACFF;
}
footer a:hover{
    color:white;
}
.footerIcon{
    font-size: 3em;
    margin-bottom: 50%;
    margin-top: 20%;
    color:white;
    text-align: center;
}
.footerIcon:hover{
    color:#0CACFF;
}
.mailFooter{
    margin-top: 8%;
    font-size: 3.75em;
}
@media (max-width:991px){
    .footerIconContainer{
        font-size: 1em;
        padding-bottom: 10%;
    }
    .footerIcons{
        
        margin: 0px;
        padding:0px;
        display: flex;
        flex-direction: row;
    }
    .footerIconContainer a{
        font-size: 1em;
        margin: 0px;
        padding:0px;
    }
    .footerIcon{
        margin: 0px;
        font-size: 2em;
    }
    .directLink{
        padding-bottom: 1em;
    }
}
@media(min-width:992px){
    #thumbnail{
        width:25vw;
        max-width: 35em;
        height:auto;
    }
    ul{
       flex-direction: row; 

    }.title{
        
        font-size: 3vw;
    }.r1{
        width:70%;
        height: 100%;
        border-radius: 3em;
    }
    .thumb{
        padding-bottom: 4%;
        
    }
    .artContainer{
        max-width: 90%;
    }
    
}
@media(max-width:991px){
    #thumbnail{
        width:35vw;
        height:auto;
    }
    ul{
        flex-direction: row;
    }
    .title{
        
    margin-top: 13%;

    }.thumb{
        padding-bottom: 2em;
        margin-left: 10%;
        max-width: 80%;
    }
    .artContainer{
        max-width: 50%;
    }
    .r1{
        width:80vw;
        max-width: 100%;
    }
}
.title{
    text-align: center;
    font-weight: bold;
    font-size: calc(2em+3vw);
    font-family: Poppins,sans-serif;
    color:black
}
.artContainer{
    padding-bottom: 20px;
    max-width: 80%;
    align-items: center;
    text-align: center;
    
    display: inline;
}
#art{
    background-image: linear-gradient(135deg,#EF0D74,#FF6A00);
    height: auto;
    padding-top: 4em;
    
    margin-bottom: 4em;
    text-align: center;
    width:80%;
    margin-left: 10%;
    border-radius: 2em;
    box-shadow:0px 15px 30px rgba(0,0,0,0.5)
}
a:hover{
    text-decoration: none;
}
.thumb{
    align-items: center;
    
}
.r1{
    padding: 0;
    border-radius: 2vh;
    box-shadow:0px 15px 30px rgba(0,0,0,0.75)
}

.titleNF{
    text-align: center;
    padding-top: 1%;
    font-weight: normal;
    font-size: calc(2em+3vw);
    font-family: Raleway,sans-serif;
    color:white !important;
    padding-bottom: 2em;
}
.notFoundContent a{
    color: white;
    text-decoration: underline;
}
.notFoundContent{
    color:white;
    padding-top: 0%;
    font-family: Raleway,sans-serif;
    margin-top: 1.5em;
    font-weight: normal;
    max-width: 75%;
    font-size: 2em;
}
#containerNF{
        background-image: linear-gradient(135deg,rgb(255, 198, 12),rgb(255, 0, 0));
        width:80%;
        margin-left:10%;
        margin-top: 10%;
        margin-bottom: 2%;
        padding-bottom: 2em;
        border-radius: 2em;
        text-align: center;
        box-shadow:0px 15px 30px rgba(0,0,0,0.5)
       
}
@media(max-width:991px){
    
    #containerNF{
        
        margin-top: 16%;

    }
}

@media(min-width:992px){
    .logo{
        width:25vw;
        max-width: 35em;
        height:auto;
        margin-left: 35%;
    }
    ul{
       flex-direction: row; 

    }.title{
        
        font-size: 3vw;
    }#bvr{
        
        margin-top: 10%;
    }
    
}
@media(max-width:991px){
    .logo{
        width:35vw;
        height:auto;
        margin-left: 28%;
    }
    ul{
        flex-direction: row;
    }
    .title{
        
        margin-top: 13%;

    }
    #bvr{
        margin-top: 20%;
    }
}
.logo{
    height:auto;
}
.title{
    text-align: center;
    font-weight: bold;
    font-size: calc(2em+3vw);
    font-family: Poppins,sans-serif;
    color:black
}
.balanceContent{
    font-weight: normal;
    font-size: 1.5em;
    font-family: Poppins,sans-serif;
    max-width: 75%;
    text-align: center;
    color: white;
    padding-bottom: 2%;
}
br{
    line-height: 4em;
}
#bvr{
    background-color: #09b5c1;
    width:80%;
    margin-left: 10%;
    border-radius: 2em;
    box-shadow:0px 15px 30px rgba(0,0,0,0.5)
}
iframe{

    margin-left:10%;

    max-width: 80%;
    border-radius: 2em;
    
    width:100%;

    height: 100%;

    border: none;

    position: absolute;
    box-shadow:0px 15px 30px rgba(0,0,0,0.5);
    
    top:0;

    left:0;

}

#iframeContainer{


    position: relative;

    width: 100%;

    height: 100%;

    padding-bottom: 50%;


}

.customNav{

    text-align: center;

    font-family: 'Raleway';

    font-size: 2vw;

    font-weight: normal;

    

}
nav{

    background-color: rgba(255, 255, 255, 1);

    width: 100%;

    display: flex;

    align-items: center;

    flex-direction: row;

}

.homeNav{

    display: none;

}

.navBarLink{

    color:black !important;

    text-align: center;

}
.navBarLink:hover{
    color: #0CACFF !important
}

.navbar-expand-lg .navbar-nav .nav-link{

    padding-right: 1em !important;

    text-align: center;

    

}

.customToggle{

    background-color: rgba(0, 0, 0, 0.0);

    fill:black;

    color:white;

    padding-left: 30%;

    box-shadow: none !important;

}

.navbar-light .navbar-toggler{

    border-color: rgba(0, 0, 0, 0.0) !important;

}

button:focus{

    outline: none !important;

}

.navbar-light .navbar-toggler-icon{

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='20' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;

}


@media all and (max-width:991px){

    .customNav{

        left:0;

        right:0;

        font-size: 3em;

        font-weight: lighter;

    }

    .customToggle{

        padding-left: 0;

    }

    .navbar-expand-lg .navbar-nav .nav-link{

        background-color: white;

        padding-right: 0 !important;

        

    }

    .homeNav{

        display: block;



    }

}
.galleryContainer{
    padding-bottom: 20px;
    max-width: 80%;
    align-items: center;
    text-align: center;
    margin-left: 10%;
}
a:hover{
    text-decoration: none;
}
.jcScreen{
    align-items: center;
    
}
.s1{
    padding: 0;
    border-radius: 2vh;
    box-shadow:0px 15px 30px rgba(0,0,0,0.5)
}
.s2{
    padding:0;
    
    border-radius: 4vw;
    box-shadow:0px 15px 20px rgba(0,0,0,0.5)
}
.s3{
    padding:0;
    
    border-radius: 2vw;
    box-shadow:0px 15px 30px rgba(0,0,0,0.5)
}
@media(min-width:992px){
    .s1{
        width:18em;
        border-radius: 3em;
    }
    .s2{
        
        border-radius: 3em;
        width:20em;
    }
    .s3{
        
        border-radius: 3em;
        width:18em;
    }
}
@media(max-width:991px){
    .jcScreen{
        padding: 0;
    }
    .galleryContainer{
        max-width: 70%;
        margin-left: 9%;
        display: flex;
    }
    .s1{
        width:6em;
    }
    .s2{
        width:8em;
    }
    .s3{
        width:6em;
    }
}
@media (min-width:992px){
    #concert{
        width:100%;
        height: auto;
        margin-top: 3%;
    }
}
@media (max-width:991px){
    #concert{
        width:100%;
        height:100%;
        margin-top: 10%;

    }
}
.sectionHeader{
    margin-left:13%;
    padding-top: 6vh;
    font-weight: bold;
    font-size: 3em;
    font-family: Poppins,sans-serif;
}
.jazzChords{
    background-image: linear-gradient(#0CACFF,#006FFF);
    width:80%;
    margin-left:10%;
    border-radius: 2em;
    text-align: center;
    box-shadow:0px 15px 30px rgba(0,0,0,0.5)
}
.jcLink{
    color:white;
    text-decoration: underline;
}
.jazzContent{
    color:white;
    padding-top: 0%;
    margin-top: 1.5em;
    text-align: center;
    max-width: 75%;
}
.jazzHeader{
    margin-left: 0;
    padding-top: 1.5em;
    font-weight: bold;
    font-size: 3em;
    color:white;
    font-family: Poppins,sans-serif;
}
p{
    margin-left:13%;
    padding-top: 1%;
    font-weight: normal;
    font-size: 1.5em;
    font-family: Poppins,sans-serif;
    max-width: 65%;
}
#jcContainer{
    margin-top: 10%;
}
@media(max-width:991px){
    #jcContainer{
        margin-top: 6em;
    }
}
@media (max-width:991px){
    .ResumeHeader{
        min-width: 50%;
        max-width: 100%;
        text-align: center;
    }
    .resumeList{
        text-align: center;
    }
    .resumeIndiv{
        text-align: center;
        margin-bottom: 2em;
        margin-top: 3em;
    }
}
@media (min-width:992px){
    .ResumeHeader{
        min-width: 30%;
        max-width: 35%;
    }
    .resumeIndiv{
        
        margin-top: 6%;
        margin-bottom: 2%;
    }
}



.resumeList{
    margin-left: 12%;
    margin: 0px;
    padding:0px;
    text-align: left;
    justify-content: left;
    border-top: solid gray 1px;
}
.ResumeHeader{
    font-weight: bold;
    font-size: 2.5em;
    color: white;
    background-color: black;
    text-align: center;
    padding:.75em;
    margin-bottom: 0px;
}
.resumeIndiv{
}
.year{
    font-weight: bold;
    font-size: 1.5em;
}
.role{
    color:gray;
}
.desc{
    font-size: 2em;
    font-weight: lighter;
}
#resumeTable{
    margin-left: 12%;
    max-width: 80%;
}
#resumeContainer{
}
