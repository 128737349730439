@media(min-width:992px){
    .logo{
        width:25vw;
        max-width: 35em;
        height:auto;
        margin-left: 35%;
    }
    ul{
       flex-direction: row; 

    }.title{
        
        font-size: 3vw;
    }#bvr{
        
        margin-top: 10%;
    }
    
}
@media(max-width:991px){
    .logo{
        width:35vw;
        height:auto;
        margin-left: 28%;
    }
    ul{
        flex-direction: row;
    }
    .title{
        
        margin-top: 13%;

    }
    #bvr{
        margin-top: 20%;
    }
}
.logo{
    height:auto;
}
.title{
    text-align: center;
    font-weight: bold;
    font-size: calc(2em+3vw);
    font-family: Poppins,sans-serif;
    color:black
}
.balanceContent{
    font-weight: normal;
    font-size: 1.5em;
    font-family: Poppins,sans-serif;
    max-width: 75%;
    text-align: center;
    color: white;
    padding-bottom: 2%;
}
br{
    line-height: 4em;
}
#bvr{
    background-color: #09b5c1;
    width:80%;
    margin-left: 10%;
    border-radius: 2em;
    box-shadow:0px 15px 30px rgba(0,0,0,0.5)
}
iframe{

    margin-left:10%;

    max-width: 80%;
    border-radius: 2em;
    
    width:100%;

    height: 100%;

    border: none;

    position: absolute;
    box-shadow:0px 15px 30px rgba(0,0,0,0.5);
    
    top:0;

    left:0;

}

#iframeContainer{


    position: relative;

    width: 100%;

    height: 100%;

    padding-bottom: 50%;


}