@media(min-width:992px){
    #thumbnail{
        width:25vw;
        max-width: 35em;
        height:auto;
    }
    ul{
       flex-direction: row; 

    }.title{
        
        font-size: 3vw;
    }.r1{
        width:70%;
        height: 100%;
        border-radius: 3em;
    }
    .thumb{
        padding-bottom: 4%;
        
    }
    .artContainer{
        max-width: 90%;
    }
    
}
@media(max-width:991px){
    #thumbnail{
        width:35vw;
        height:auto;
    }
    ul{
        flex-direction: row;
    }
    .title{
        
    margin-top: 13%;

    }.thumb{
        padding-bottom: 2em;
        margin-left: 10%;
        max-width: 80%;
    }
    .artContainer{
        max-width: 50%;
    }
    .r1{
        width:80vw;
        max-width: 100%;
    }
}
.title{
    text-align: center;
    font-weight: bold;
    font-size: calc(2em+3vw);
    font-family: Poppins,sans-serif;
    color:black
}
.artContainer{
    padding-bottom: 20px;
    max-width: 80%;
    align-items: center;
    text-align: center;
    
    display: inline;
}
#art{
    background-image: linear-gradient(135deg,#EF0D74,#FF6A00);
    height: auto;
    padding-top: 4em;
    
    margin-bottom: 4em;
    text-align: center;
    width:80%;
    margin-left: 10%;
    border-radius: 2em;
    box-shadow:0px 15px 30px rgba(0,0,0,0.5)
}
a:hover{
    text-decoration: none;
}
.thumb{
    align-items: center;
    
}
.r1{
    padding: 0;
    border-radius: 2vh;
    box-shadow:0px 15px 30px rgba(0,0,0,0.75)
}
