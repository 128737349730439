@media (min-width:992px){
    form input{
        width: 60%;
    }
    .title{
        
        font-size: 3vw;
    }
}
@media (max-width:991px){
    form input{
        width:60%
    }
    .title{
        margin-top: 13%;
    }.iconContainer{
    
        
        margin: 0px;
        padding:0px;
        display: flex;
        flex-direction: row;
    }
}
form{
    text-align: center;
    margin-left: 13%;
}
#m{
    padding-bottom: 5em;
}
form input{
    margin-left: 13%;
    outline: none;
    border: none;
    display: block;
    border-bottom: 2px solid black;
    margin-top: 2em;
}
form input:hover{
    border-bottom: 2px solid #0CACFF;
}
.iconsContact{
    font-size: 3em;
    margin-top: 50%;
}
.mailIcon{
    font-size: 3.75em;
    margin-top: 30%;
}
.buttonWrap{
    z-index: -1;
    width: 300%;
    height: 100%;
    top: 0;
    left:0;
}
.submit{
    margin-top: 2em;
    margin-left: -15%;
    padding: 0 30px;
    border: none;
    text-decoration: none;
    border-radius: 20px !important;
    width:auto;
    height:60px;
    font-size: 1.25em;
    font-family: Poppins,Raleway,sans-serif;
    align-items: center;
    line-height: 1.2;
    color: white;
    background:linear-gradient( #0CACFF,#006FFF) ;
}

.submit:hover{
    background:#006FFF;
    box-shadow:0px 5px 10px rgba(0,0,0,0.5);
}
.submit:active{
    color: black;
    background:#FFF;
    box-shadow:0px 5px 10px rgba(0,0,0,0.5);
}
.title{
    margin-left: 0;
    text-align: center;
    font-weight: bold;
    font-size: calc(2em+3vw);
    
    font-family: Poppins,sans-serif;
    color:black
}
strong{
    color:red;
    margin-top: 2em;
    font-size: 2em;
    display: block;
    text-align: center;
}
