@media (min-width:992px){
    #concert{
        width:100%;
        height: auto;
        margin-top: 3%;
    }
}
@media (max-width:991px){
    #concert{
        width:100%;
        height:100%;
        margin-top: 10%;

    }
}
.sectionHeader{
    margin-left:13%;
    padding-top: 6vh;
    font-weight: bold;
    font-size: 3em;
    font-family: Poppins,sans-serif;
}
.jazzChords{
    background-image: linear-gradient(#0CACFF,#006FFF);
    width:80%;
    margin-left:10%;
    border-radius: 2em;
    text-align: center;
    box-shadow:0px 15px 30px rgba(0,0,0,0.5)
}
.jcLink{
    color:white;
    text-decoration: underline;
}
.jazzContent{
    color:white;
    padding-top: 0%;
    margin-top: 1.5em;
    text-align: center;
    max-width: 75%;
}
.jazzHeader{
    margin-left: 0;
    padding-top: 1.5em;
    font-weight: bold;
    font-size: 3em;
    color:white;
    font-family: Poppins,sans-serif;
}
p{
    margin-left:13%;
    padding-top: 1%;
    font-weight: normal;
    font-size: 1.5em;
    font-family: Poppins,sans-serif;
    max-width: 65%;
}